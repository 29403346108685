import React from 'react';
import config from '../../config';
import ombreLogo from '../assets/svg/ombre-logo.svg';

export default () => (
  <footer id="footer">
    <ul className="icons">
      {config.socialLinks.map(social => {
        const { style, icon, name, url } = social;
        return (
          <li key={url}>
            <a href={url} className={`icon ${style} ${icon}`}>
              <span className="label">{name}</span>
            </a>
          </li>
        );
      })}
    </ul>
    <ul className="copyright">
      <li>
        &copy;
        <a href="http://www.ald2techsolutions.com/" target="_new">
          ALD<sup>2</sup> Technology Solutions
        </a>
      </li>
      <li>
        <a href="https://www.giannasnellphotography.com/" target="_new">
          Gianna Snell Photography
        </a>
      </li>
    </ul>

    <div className="row gtr-50 gtr-uniform">
      <div className="col-12">
        <span className="image fit">
          <img src={ombreLogo} alt="" />
        </span>
      </div>
    </div>
  </footer>
);
