import React from 'react';
import { Link } from 'gatsby';
import whiteLogo from '../assets/svg/white-logo.svg';

const logoStyle = {
  'max-width': 72,
  'margin-top': 16,
  'text-shadow': '2px 2px 4px #000000',
};

export default function Nav({ onMenuToggle = () => {} }) {
  return (
    <nav id="nav">
      <ul>
        <li className="special">
          <a href="#top">
            <img src={whiteLogo} style={logoStyle}></img>
          </a>
        </li>
      </ul>
    </nav>
  );
}
