module.exports = {
  siteTitle: 'Dudbots', // <title>
  manifestName: 'dudbots-v4',
  manifestShortName: 'dudbots-v4', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#663399',
  manifestThemeColor: '#663399',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/website-icon.png',
  // pathPrefix: `/gatsby-starter-spectral/`, // This path is subpath of your hosting https://domain/portfolio
  heading: 'The Dudbots',
  subHeading: 'Ordinary kids serving an extraordinary God',
  // social
  socialLinks: [
    {
      style: 'brands',
      icon: 'fa-youtube',
      name: 'YouTube',
      url: 'http://youtube.com/c/dudbots',
    },
    {
      style: 'solid',
      icon: 'fa-shopping-bag',
      name: 'Merch',
      url: 'https://store.dudbots.com',
    },
    {
      style: 'brands',
      icon: 'fa-facebook',
      name: 'Facebook',
      url: 'https://facebook.com/dudbots',
    },
    {
      style: 'brands',
      icon: 'fa-instagram',
      name: 'Instagram',
      url: 'https://instagram.com/dudbots',
    },
    {
      style: 'solid',
      icon: 'fa-envelope',
      name: 'Email',
      url: 'https://forms.gle/mGzh4Jaaeohso19v6',
    },
    {
      style: 'solid',
      icon: 'fa-dollar-sign',
      name: 'CashApp',
      url: 'https://cash.app/$dudbots',
    },
  ],
};
